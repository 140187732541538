$(function  () {
	$('input[type="text"], textarea').each(function  () {
		var txtValue = $(this) .val();

		$(this).focus(function  () {
			if ( $(this).val() == txtValue ) {
				$(this).val('');
			}
		});
		$(this).blur(function  () {
			if ( $(this).val() === '' ) {
				$(this).val(txtValue);
			}
		});
	});
});