$(function  () {

	// show mobile navigation
	$('#nav-toggle').on('click', function  () {
		$('#global-nav').toggleClass('active');
		// $('#global-nav').slideToggle().toggleClass('active');
		$('#global-header').toggleClass('active');
		$(this).toggleClass('active');
	});

	// make sure mobile nav does not show when resizing window
	$(window).resize(function  () {
		var ww = $(window).width();
		if(ww >= 992) {
			$('#global-header, #global-nav, #nav-toggle').removeAttr('class');
		}
	});

	// add and remove active class from global header
	$(window).scroll(function  () {
		var y = $(this).scrollTop();
		if(y > 280) {
			$('#global-header').addClass('scroll-active');
		} else {
			$('#global-header').removeClass('scroll-active');
		}
	});

	// if scrolled down page add active class to global header
	var checkScrollTop = function  () {
		var top = $(window).scrollTop(),
		hh = 60;
		if ( top >= hh ) {
			$('#global-header').addClass('scroll-active');
		}
	}();


	// var submenuItems = [
	// ['#sub-about', 'a.submenu.about'],
	// ['#sub-services', 'a.submenu.services']
	// ];


	// var submenuHover = function (submenuArray) {
	// 	$(submenuArray).each(function  () {

	// 		var triggerNavElement = $(this)[0],
	// 		showSubmenu = $(this)[1];

	// 		console.log(triggerNavElement);
	// 		console.log(showSubmenu);

	// 		$(triggerNavElement, showSubmenu).mouseenter(function  () {
	// 			$(showSubmenu).attr('data-open', 'true');
	// 		}).mouseleave(function  () {
	// 			$(showSubmenu).attr('data-open', 'false');
	// 		});
	// 	});
	// }(submenuItems);


	// submenuHover(submenuItems);

	// submenu control
	// $('#global-nav > li, .submenu').mouseenter(function  () {
	// 	$('.submenu').attr('data-open', 'true');
	// }).mouseleave(function  () {
	// 	$('.submenu').attr('data-open', 'false');
	// });

	// hide helper
	$('#mq-size').on('click', function  () {
		$(this).fadeOut('slow');
	});



});

